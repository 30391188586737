#intro-video {
  display: none;

  @include breakpoint($bp-m) {
    display: block;
  }
}

#promo-video,
#promo-video-close-btn {
  @include breakpoint($bp-m) {
    visibility: hidden;
  }

  .promo-visible & {
    visibility: visible;
  }
}

#promo-video-close-btn {
  display: none;

  @include breakpoint($bp-m) {
    background: #000;
    border: none;
    border-radius: 4px;
    display: inline-block;
    margin: 0;
    padding: 14px;
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 1;
  }

  &.hide {
    display: none;
  }
}