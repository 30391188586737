body {
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  line-height: 1.42;
  letter-spacing: 0.15px;
  text-align: center;
  -webkit-font-smoothing: antialiased;
}

.wf-active body {
  font-family: 'aktiv-grotesk-std', 'Arial', sans-serif;
  line-height: 1.4;
  letter-spacing: 0px;
}

h2 {
  font-size: 22px;
  font-weight: lighter;
  margin: 0 0 20px 0;

  @include breakpoint($bp-m) {
    font-size: 26px;
    margin-bottom: 40px;
  }
}

h3 {
  font-weight: bold;
  margin: 10px 0;
}

button {
  -webkit-appearance: none;
  appearance: none;
}

img {
  height: auto;
  max-width: 100%;
}

section p {
  font-weight: lighter;
  line-height: 1.65;
  letter-spacing: 0.3px;
}
