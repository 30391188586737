.ribbon {
  display: table;
  height: 3px;
  position: relative;
  width: 100%;
  z-index: 1;

  > div {
    display: table-cell;
  }
}
