.color-list {
  display: flex;
  flex-wrap: wrap;

  @include breakpoint($bp-s) {
    flex-wrap: nowrap;
  }
}

.color-list__item {
  font-size: 14px;
  margin-bottom: 20px;
  position: relative;
  width: 50%;

  &:after {
    @include breakpoint($bp-m) {
      background: #ecedec;
      content: '';
      height: 200px;
      left: 50%;
      margin-left: -1px;
      position: absolute;
      top: -200px;
      width: 2px;
      z-index: -1;
    }
  }

  @include breakpoint($bp-s) {
    flex: 1 1 auto;
    margin-bottom: 0;
    width: 25%;
  }

  @include breakpoint($bp-m) {
    font-size: 16px;
  }

  &:nth-child(2) {
    @include breakpoint($bp-m) {
      margin-right: 175px;
    }
  }
}

.color-list__item__lantern-wrap {
  height: 80px;
  margin: 0 auto 10px;
  position: relative;
  width: 80px;

  @include breakpoint($bp-m) {
    height: 75px;
    width: 75px;
  }

  @include breakpoint($bp-l) {
    margin-bottom: 30px;
    height: 125px;
    width: 125px;
  }
}

.color-list__item__lantern {
  bottom: 0;
  left: 0;
  overflow: visible;
  position: absolute;
  right: 0;
  top: 0;

  @include breakpoint($bp-m) {
    opacity: 0.55;

    &.-glow {
      opacity: 0;
      transition: all 1.45s ease-in-out;

      .color-list__item.active & {
        opacity: 1;
      }
    }
  }
}
