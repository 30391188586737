.reading {
  background: #f2f2f2;
  overflow: hidden;
  padding: 30px 0;
  text-align: left;

  @include breakpoint($bp-m) {
    background: #fff;
  }

  h2 {
    color: #929292;
    font-size: 15px;
    font-weight: normal;
    margin: 10px 0;
    text-transform: uppercase;
  }

  h3 {
    margin: 0;
  }

  img {
    display: none;

    @include breakpoint($bp-m) {
      float: left;
      display: block;
      margin-right: 40px;
      max-width: 200px;
    }
  }
}

.reading__hdr {
  color: #000;
  font-size: 22px;
  font-weight: 100;
  text-decoration: none;

  @include breakpoint($bp-s) {
    font-size: 32px;
  }

  @include breakpoint($bp-m) {
    font-size: 22px;
  }

  @include breakpoint($bp-l) {
    font-size: 32px;
  }
}
