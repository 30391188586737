.footer {
  background: #383e44;
  color: #fff;
  text-align: left;

  a {
    color: #5fc7f3;
    font-size: 18px;
    text-decoration: none;
    transition: text-shadow 0.15s ease-in-out;

    &:focus,
    &:hover {
      text-shadow: 0 0 10px rgba(#5fc7f3, 0.4);
    }
  }

  h2 {
    margin-bottom: 10px;
  }

  .grid {
    @include breakpoint($bp-s) {
      flex-direction: row;
      flex-wrap: wrap;
    }

    @include breakpoint($bp-m) {
      flex-wrap: nowrap;
    }

    > .cell {
      padding: 30px;

      @include breakpoint($bp-s) {
        width: 50%;
      }

      @include breakpoint($bp-m) {
        width: 100%;
      }
    }
  }
}

.footer__dark {
  align-items: center;
  background: #31363b;
  display: flex;

  @include breakpoint($bp-s) {
    width: 100% !important;
  }
}

.footer__dark__inner {
  color: #838689 !important;
  display: block;
  margin: 0 auto;

  &:focus,
  &:hover {
    text-shadow: none !important;
  }

  > img,
  > span {
    display: block;
  }

  > span {
    margin: 10px 0;
  }
}

.footer__dark__text {

  @include breakpoint($bp-m) {
    font-size: 13px;
  }

  @include breakpoint($bp-l) {
    font-size: 16px;
  }
}