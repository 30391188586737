.components {
  background: #f8f8f8;
  text-align: left;

  h2 {
    margin-bottom: 0;
  }

  .grid {
    margin-bottom: 20px;

    @include breakpoint($bp-m) {
      margin-bottom: 60px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .cell {
    @include breakpoint($bp-m) {
      &:nth-child(1) {
        padding-right: 5%;
      }

      &:nth-child(2) {
        padding-left: 5%;
      }
    }

    h3 {
      @include breakpoint($bp-m) {
        margin-top: -10px;
      }
    }

    img {
      display: block;
      margin: 0 auto;
      max-width: 360px;
      width: 85%;

      @include breakpoint($bp-m) {
        display: inline;
        max-width: 100%;
        width: 100%;
      }
    }
  }
}

.components__orb {
  align-items: center;

  .cell:first-child {
    @include breakpoint($bp-m) {
      padding-right: 2%;
      width: 75%;
    }
  }
}

.components__custom {
  background: #fff;

  @include breakpoint($bp-m) {
    background: #f2f2f2;
    position: relative;
  }

  &:after {
    @include breakpoint($bp-m) {
      background: url('../images/graba-bg.jpg') no-repeat;
      background-size: cover;
      content: '';
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      width: 55%;
    }
  }
}

.components__custom__cell {
  @include breakpoint($bp-m) {
    padding: 60px 0;
    width: 35%;
  }

  .cell img {
    margin-top: 20px;

    @include breakpoint($bp-m) {
      display: none;
    }
  }
}
