.hero-wrapper {
  @include breakpoint($bp-m) {
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
    z-index: 0;
  }
}

.hero {
  display: flex;
  flex-direction: column;
  z-index: 0;
}

.hero__media {
  height: 0;
  order: 2;
  padding-bottom: 56.25%;
  position: relative;
  z-index: 0;

  @include breakpoint($bp-m) {
    height: auto;
    order: initial;
    padding: 0;
    position: absolute;
    z-index: -1;

    &:after {
      background: rgba(0, 0, 0, 0.4);
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;

      .promo-visible & {
        opacity: 0;
      }
    }

    .promo-visible & {
      z-index: 0;
    }
  }

  > video {
    display: block;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    .promo-visible & {
      z-index: 2;
    }
  }
}

.hero__logo {
  align-items: center;
  align-self: center;
  display: flex;
  padding: 10px;

  @include breakpoint($bp-m) {
    align-self: flex-start;
    min-height: 92px;
    padding: 32px 0 0 32px;
    transition: opacity 0.25s ease-in-out;

    .promo-visible & {
      opacity: 0;
    }
  }
}

.hero__logo__lantern {
  margin-right: 15px;
  width: 40px;

  @include breakpoint($bp-m) {
    opacity: 0.75;
    width: 45px;
  }

  path {
    @include breakpoint($bp-m) {
      stroke: #fff;
    }
  }
}

.hero__logo__text {
  fill: #000;

  @include breakpoint($bp-m) {
    fill: #fff;
  }
}

.hero__callout {
  align-items: center;
  align-self: center;
  background: #383e44;
  color: #fff;
  display: flex;
  order: 3;
  padding: 20px;
  width: 100%;

  @include breakpoint($bp-m) {
    background: none;
    height: 100%;
    max-width: 700px;
    order: initial;
    padding: 40px 40px 0 40px;
    transition: opacity 0.25s ease-in-out;
    width: auto;
  }

  @include breakpoint($bp-l) {
    padding-top: 100px;
  }

  .promo-visible & {
    opacity: 0;
  }

  > div {
    width: 100%;
  }
}

.hero__hdr {
  font-size: 18px;
  font-weight: lighter;

  @include breakpoint($bp-m) {
    font-size: 34px;
  }

  @include breakpoint($bp-l) {
    font-size: 42px;
  }
}

.hero__btn {
  display: none;

  @include breakpoint($bp-m) {
    background: #2ba8dd;
    border-radius: 4px;
    border: none;
    box-shadow: 0 0 36px 12px rgba(#fff, 0);
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 20px;
    font-weight: lighter;
    margin-top: 50px;
    padding: 10px 22px;
    transition: box-shadow 0.2s ease-in-out;

    &:before,
    > span {
      vertical-align: middle;
    }

    &:before {
      background: url('../images/thumbnail-play.jpg') no-repeat;
      background-size: cover;
      content: '';
      display: inline-block;
      margin-right: 12px;
      height: 55px;
      width: 75px;
    }
  }

  &:focus,
  &:hover {
    box-shadow: 0 0 36px 12px rgba(#2ba8dd, 0.3);
  }
}

.hero__social {
  display: none;

  @include breakpoint($bp-m) {
    display: block;
    position: absolute;
    right: 15px;
    top: 54px;
    z-index: 0;
  }
}

.hero__social__item {
  display: inline-block;
  vertical-align: middle;

  iframe {
    width: 90px !important;
  }
}


