.lantern {
  @include breakpoint($bp-m) {
    position: relative;

    &:after {
      background: #73767c;
      content: '';
      height: 32px;
      left: 50%;
      margin-left: -1px;
      position: absolute;
      top: -32px;
      width: 2px;
      z-index: 2;
    }
  }
}
