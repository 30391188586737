.btn {
  border-radius: 4px;
  border: 1px solid #2ba8dd;
  color: #2ba8dd;
  display: inline-block;
  margin: 20px 0;
  padding: 6px 12px;
  text-decoration: none;
  transition: all 0.15s ease-in-out;

  &:focus,
  &:hover {
    background: #2ba8dd;
    color: #fff;
  }
}
