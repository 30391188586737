.center {
  text-align: center;
}

.container {
  padding: 20px;

  @include breakpoint($bp-m) {
    padding: 40px;
  }

  @include breakpoint($bp-l) {
    padding: 40px 100px;
  }
}

.container-medium {
  padding: 20px;

  @include breakpoint($bp-m) {
    padding: 40px 60px;
  }

  @include breakpoint($bp-l) {
    padding: 40px 140px;
  }
}

.cover {
  @include breakpoint($bp-m) {
    bottom: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
  }
}

.no-overflow {
  overflow: hidden;
}

.hide-at-m {
  @include breakpoint($bp-m) {
    display: none;
  }
}

.show-at-m {
  display: none;

  @include breakpoint($bp-m) {
    display: block;
  }
}

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px !important;
}

.available {
  color: #399b4b;
}

.occupied-soon {
  color: #d3a939;
}

.occupied {
  color: #b1382f;
}

.almost-free {
  color: #2489c7;
}

.bg-available {
  background: #65ba4d;
}

.bg-occupied-soon {
  background: #fccd30;
}

.bg-occupied {
  background: #dd362f;
}

.bg-almost-free {
  background: #3bc5f1;
}