.grid {
  display: flex;
  flex-direction: column;

  @include breakpoint($bp-m) {
    flex-direction: row;
  }

  > .cell {
    min-width: 0px; /* thanks, Firefox! */
    width: 100%;
  }
}
